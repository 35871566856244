/* .image-container {
  position: relative;
}

.image-container img {
  width: 100%;
  height: auto;
}

.card-container {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; 

}




.card {
  position: absolute;
  width: 150px;
  height: 300px;
  background-color: transparent; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: moveLeft 5s linear forwards;
  background: url('./Image/offer.jpg') no-repeat center center; 
  background-size: cover;
}


@keyframes moveLeft {
  0% {
    right: -150px; 
  }
  100% {
    right: 470%; 
    transform: translateX(50%);   }
}



.scholarship-marquee-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; 
}





.content-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	z-index: 1;
}

.abs-fs {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-perspective: 1200px;
          perspective: 1200px;
  opacity: 0.23;
}

.content-wrapper::before {
	content: '';
	display: inline-block;
	height: 100%;
	margin-right: -0.25em;
	vertical-align: middle;
}

.v-center {
	position: relative;
	display: inline-block;
	width: 100%;
  
    height: 440px;
	text-align: center;
	vertical-align: middle;
}

.main-content {
	max-width: 80%;
	margin: 3.75em auto;
	font-size: 16px;
	text-align: left;
	line-height: 30px;
}
.main-content .heading{
font-weight: 900;
font-size: xxx-large;
}





.abs-center {
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(50%,-50%); 
	-webkit-transform: translate(50%,-50%);
  	-moz-transform: translate(50%,-50%); 
	-o-transform: translate(50%,-50%); 
	transform: translate(50%,-50%);
}

.screen {
  visibility: hidden;
}

.canvas {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 70%;
  height: 500px;
  -webkit-transform: skewX(-40deg) translate3d(0, 0, 0);
          transform: skewX(-40deg) translate3d(0, 0, 0);
}

 */


 .LMS__back{
 background: url('./Image/BLMS.webp') no-repeat center center; 
  background-size: cover;
  /* height: 400px;
  width: 100%; */
 }