
.error_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gif {
  display: flex;
  justify-content: center;
}

.error_content {
  text-align: center;
  margin: 3rem 0;
}

.error_content .error_main-heading {
  font-size: 2.5rem;
  font-weight: 700;
}
.error_p{
  font-size: 1.3rem;
  padding: 0.7rem 0;
}

.error_btn {
  padding: 1rem;
  border-radius: 15px;
  outline: none;
  border: none;
  background: #0046d4;
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
}
