@tailwind base;
@tailwind components;
@tailwind utilities;


.btn {
    background: linear-gradient(0deg, #00acee, #027efb);
    border: none;
    color: whitesmoke;
    font: 500 1rem sans-serif;           
    position: relative;
    cursor: pointer;
    margin: 1rem;
  }
  .border-animation::before,
  .border-animation::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border: 0px solid transparent;
  }
  .border-animation::before {
    top: -4px;
    left: -4px;
    border-top: 2px solid skyblue;
    border-left: 2px solid skyblue;
  }
  .border-animation::after {
    bottom: -4px;
    right: -4px;
    border-bottom: 2px solid skyblue;
    border-right: 2px solid skyblue;
  
  
  }
  .border-animation:hover::before,
  .border-animation:hover::after {
    
    width: calc(100% + 6px);
    height: calc(100% + 6px);
  }
  
  .top_header{
    background-color: #004a99 !important;
  }
  
  @media screen and (max-width: 580px) {
    .header{
      padding: 5% !important;
    }
.header h1{
  font-size: medium !important;
}
.header_paragraph{
  font-size: small !important;
}
    .header_img{
display: none !important;
}

  }

/* Small devices (portrait tablets and large phones, 581px to 767px) */
@media (min-width: 581px) and (max-width: 767px) {
    .header{
      padding: 5% !important;
    }
.header h1{
  font-size: medium !important;
}
.header_paragraph{
  font-size: small !important;
}
    .header_img{
display: none !important;
}

  }


  @media (min-width: 768px) and (max-width: 991px) {
    .header_img{
   margin-top: 40px !important;
    }
  }
